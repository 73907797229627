import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/faqs/SingleCol";
// import Features from "components/features/ThreeColWithSideImage.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const HighlightedText = tw.span`text-primary-500`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header buttonRounded={true}/>
      <MainFeature1
        subheading={<Subheading>Work at SCAS</Subheading>}
        heading="We are SCAS, a goup of hackers and developers"
        buttonRounded={true}
        primaryButtonText="Contact us"
        imageSrc=""
        primaryButtonUrl={"/contact"}
      />
      <Features 
      subheading={<Subheading>Jobs</Subheading>}
        heading={
          <>
            We are looking <HighlightedText>for</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "React / Frontend Developer",
            answer:
            <>
              <p>We are looking for react developers to join SCAS.</p>
              <p>The position can be part time or contract based, this can be determined case by case</p>
              <p>Tasks</p>
              <ul>
              <li>- web design / development in React JS</li>
              </ul>
              <p>What do we expect from a person</p>
              <ul>
                <li>- interest in crypto currencies</li>
                <li>- interest in hacking and computer science</li>
                <li>- some portfolio project developed in react</li>
                <li>- basic understanding of application development</li>
                <li>- located in Helsinki or Berlin/MV</li>
              </ul>
            </>
          },
          {
            question: "UI/UX Designer",
            answer:
            <>
            <p>We are looking for desingers to work on SCAS.</p>
            <p>The position can be part time or contract based, this can be determined case by case</p>
            <p>Tasks</p>
            <ul>
            <li>- web design</li>
            </ul>
            <p>What do we expect from a person</p>
            <ul>
              <li>- interest in crypto currencies</li>
              <li>- interest in hacking and computer science</li>
              <li>- some portfolio project</li>
              <li>- basic understanding of application development</li>
              <li>- located in Helsinki or Berlin/MV</li>
            </ul>
          </>
          }
        ]} />
      <Footer />
    </AnimationRevealPage>
  );
};
